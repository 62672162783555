@import url("https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,600");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,300,400,700");
@import url(../../node_modules/ionicons/dist/css/ionicons.min.css);
#auth-dialog-root {
  margin: 0;
  padding: 0; }

iframe {
  width: 100%;
  height: 100%;
  border: none;
  animation: 2s ease 0s normal forwards 1 fadein; }
  iframe.explanation-iframe {
    height: 1000px; }

body {
  padding: 0;
  margin: 0;
  font-size: 15px;
  color: #535252;
  width: 100%;
  height: 100%;
  /**
   * Beta
   */ }
  body .beta.mobile {
    position: fixed;
    top: 32.5px;
    left: calc(50% - 30px);
    z-index: 10000;
    width: 60px;
    height: 15px;
    line-height: 15px;
    color: #535252;
    font-size: 11px;
    text-align: center;
    background: #FDCF54;
    box-shadow: 0 0 10px rgba(80, 80, 80, 0.2);
    border-radius: 4px; }
  body .beta.desktop {
    position: fixed;
    top: 75px;
    right: 0;
    width: 100px;
    height: 100px;
    z-index: 999;
    overflow: hidden; }
    body .beta.desktop span {
      display: inline-block;
      width: 150px;
      line-height: 20px;
      background: #5ad9e7;
      color: white;
      transform: rotate(45deg);
      transform-origin: center;
      text-align: center;
      position: absolute;
      right: -40px;
      top: 25px;
      box-shadow: 0 0 10px rgba(80, 80, 80, 0.2); }
  body *, body ::after, body ::before {
    box-sizing: border-box; }
  body p {
    font-size: 14px;
    line-height: 25px;
    display: inline-block;
    float: left;
    width: 100%;
    color: #535252;
    font-weight: 300;
    margin: 0 0 10px 0; }

.MobileApp {
  position: relative;
  padding-top: 40px;
  display: inline-block;
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #f4f6f7;
  font-family: 'Titilium Web', sans-serif; }
  .MobileApp .visibility {
    height: 24px;
    width: 24px;
    background: #FDCF54;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.25);
    line-height: 24px;
    text-align: center;
    z-index: 999;
    font-size: 18px;
    box-shadow: 0 0 6px rgba(80, 80, 80, 0.2); }
  .MobileApp .loading {
    position: fixed;
    left: 0;
    top: 50px;
    right: 0;
    margin: 0 auto;
    width: 150px;
    text-align: center;
    line-height: 15px;
    padding: 5px 10px;
    color: white;
    font-size: 10px;
    z-index: 99999;
    box-shadow: 0 1px 2px 1px rgba(80, 80, 80, 0.1);
    border-radius: 3px;
    overflow: hidden; }
    .MobileApp .loading:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      background-color: #777e80;
      animation: glow 2s linear infinite;
      z-index: -1; }

.DesktopApp {
  position: relative;
  display: inline-block;
  float: left;
  width: 100%;
  padding: 75px 0 0 0;
  font-family: 'Raleway', sans-serif; }
  .DesktopApp p {
    font-family: 'Roboto Slab', sans-serif;
    font-size: 15px;
    line-height: 20px;
    text-align: left; }
    .DesktopApp p.large {
      font-size: 16px;
      line-height: 28px;
      font-weight: 300; }
  .DesktopApp .loading {
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: transparent;
    z-index: 9999;
    font-size: 0; }
    .DesktopApp .loading:before {
      display: block;
      position: absolute;
      content: "";
      left: -200px;
      width: 200px;
      height: 5px;
      background-color: rgba(255, 255, 255, 0.5);
      animation: loading 1s linear infinite; }

@keyframes loading {
  from {
    left: -200px;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

ul.panel-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
  display: inline-block; }
  ul.panel-list li {
    width: 100%;
    float: left;
    display: inline-block;
    padding: 10px 0;
    border-top: 1px solid #e4e8ea;
    text-align: left; }
    ul.panel-list li span {
      line-height: 20px;
      margin: 2px 0;
      display: inline-block;
      float: left; }

.account-header {
  padding-top: 105px;
  padding-left: 200px;
  position: relative;
  max-width: 640px;
  min-height: 180px;
  width: 100%;
  float: none;
  margin: 60px auto 30px auto; }
  .account-header h2 {
    text-align: left !important;
    margin: 0 !important; }
  .account-header h3 {
    text-align: left !important;
    margin: 5px 0 0 0 !important;
    color: #777e80;
    font-weight: 300;
    float: left;
    font-style: italic;
    font-size: 14px; }
  .account-header .Avatar {
    position: absolute !important;
    left: 0;
    top: 0; }

.truncated {
  max-height: 40px;
  line-height: 20px;
  height: auto;
  width: 100%;
  float: left;
  display: inline-block;
  overflow: hidden;
  position: relative; }
  .truncated:before {
    content: '... See more';
    position: absolute;
    right: 0;
    bottom: 0;
    color: #b2bcc1;
    background: white;
    z-index: 10; }
  .truncated:after {
    content: '';
    position: absolute;
    right: 0;
    width: 72px;
    height: 20px;
    background: white;
    z-index: 11; }

.Pointer {
  color: #5ad9e7;
  font-size: 36px;
  text-shadow: 0 -2px 10px rgba(80, 80, 80, 0.25);
  transform: translate(-10px, -36px); }

.columns {
  margin-left: -15px;
  margin-right: -15px; }
  .columns [class*='column-'] {
    padding: 0 15px;
    float: left; }
  .columns .column-1 {
    width: 100%; }
  .columns .column-2 {
    width: 50%; }
  .columns .column-3 {
    width: 33.33%; }
  .columns .column-4 {
    width: 24%; }

.fade-in {
  animation: FadeIn;
  animation-duration: 500ms;
  animation-direction: forward;
  transform-origin: center 0; }

@keyframes FadeIn {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes SlideInBottom {
  0% {
    transform: translateY(100%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes Zoom {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

@keyframes glow {
  0% {
    opacity: 0.4; }
  50% {
    opacity: 0.7; }
  100% {
    opacity: 0.4; } }

@keyframes loading {
  from {
    left: -200px;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

@keyframes bounceUpDown {
  0% {
    transform: translateY(15px); }
  70% {
    transform: translateY(0); }
  100% {
    transform: translateY(15px); } }

@media (max-width: 640px) {
  .account-header {
    margin-top: 0;
    padding-top: 180px;
    padding-left: 0;
    padding-right: 0;
    display: inline-block; }
    .account-header h2, .account-header h3 {
      width: 100%;
      text-align: center !important; }
    .account-header .Avatar {
      left: 0;
      right: 0;
      margin: auto; } }

.notification {
  margin: 0 auto;
  display: block;
  width: 100%;
  text-align: center;
  padding: 21px;
  background: #E25425;
  color: white;
  z-index: 1000;
  border-radius: 4px; }
  .notification.top {
    border-radius: 0;
    position: absolute; }

.ReactModal__Overlay {
  z-index: 10000 !important; }
  .ReactModal__Overlay .ril-toolbar {
    background: none; }
    .ReactModal__Overlay .ril-toolbar .ril-zoom-out, .ReactModal__Overlay .ril-toolbar .ril-zoom-in {
      display: none; }
    .ReactModal__Overlay .ril-toolbar .ril__closeButton {
      background-color: rgba(0, 0, 0, 0.3) !important;
      height: 40px; }

@media (max-width: 767px) {
  .ReactModal__Overlay .ril__navButtons {
    display: none; } }

.validation {
  text-transform: none;
  display: inline-block;
  float: left;
  border-radius: 4px;
  margin-top: 5px;
  background-color: #ff8d8b;
  color: white;
  line-height: 20px;
  min-height: 20px;
  padding: 0 10px;
  position: relative;
  animation-name: FadeIn;
  animation-duration: 1000ms;
  animation-direction: forward;
  opacity: 1;
  font-size: 12px;
  font-weight: 300; }
  .validation:after {
    position: absolute;
    content: " ";
    width: 0;
    height: 0;
    top: -3px;
    left: 15px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 3px solid #ff8d8b; }

.intro_info {
  margin: 10px 0 30px; }

.RecaptchaContainer {
  min-height: 78px; }
