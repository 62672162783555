footer {
  display: inline-block;
  width: 100%;
  float: left;
  height: 300px;
  border-top: 1px solid #e4e8ea; }
  footer.small {
    width: 70%; }

@media (max-width: 1214px) {
  footer {
    height: 345px; } }

@media (max-width: 991px) {
  footer.small {
    width: 100%; } }

@media (max-width: 768px) {
  footer {
    height: 790px; } }

@keyframes fadein {
  0% {
    opacity: 0; }
  66% {
    opacity: 0; }
  100% {
    opacity: 1; } }
