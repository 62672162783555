.Label {
  display: inline-block;
  float: left;
  position: relative;
  width: 100%;
  min-height: 15px;
  line-height: 15px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: left;
  color: #535252;
  font-weight: 600;
  margin-bottom: 5px; }
  .Label.danger {
    color: #E25425; }
