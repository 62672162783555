.DesktopApp .Nav {
  position: fixed;
  left: 0;
  top: 0;
  height: 75px;
  width: 100%;
  padding: 5px 0 0 0;
  background-color: white;
  z-index: 9999;
  border-bottom: 1px solid #e4e8ea; }
  .DesktopApp .Nav:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 5px;
    background: #5ad9e7;
    background: -moz-linear-gradient(left, #5ad9e7 0%, #01bbee 100%);
    background: -webkit-linear-gradient(left, #5ad9e7 0%, #01bbee 100%);
    background: linear-gradient(to right, #5ad9e7 0%, #01bbee 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5ad9e7', endColorstr='#01bbee', GradientType=1); }
  .DesktopApp .Nav .logo-wrapper {
    display: inline-block;
    float: left;
    height: 70px;
    width: auto;
    padding: 5px 20px; }
    .DesktopApp .Nav .logo-wrapper .Logo {
      float: left;
      transition: all ease-out 150ms;
      cursor: pointer; }
      .DesktopApp .Nav .logo-wrapper .Logo:hover {
        opacity: 0.75;
        transition: all ease-out 150ms; }
  .DesktopApp .Nav ul {
    list-style-type: none;
    padding: 0;
    display: inline-block;
    width: auto; }
    .DesktopApp .Nav ul.menu {
      float: right;
      padding: 20px;
      border-left: 1px solid #e4e8ea;
      margin: 0; }
      .DesktopApp .Nav ul.menu li {
        float: left;
        display: inline-block;
        height: 30px; }
        .DesktopApp .Nav ul.menu li span {
          line-height: 20px;
          padding: 5px 10px;
          font-weight: 300 !important;
          display: inline-block;
          float: left;
          text-align: center; }
        .DesktopApp .Nav ul.menu li a {
          position: relative;
          line-height: 20px;
          padding: 5px 10px;
          color: #535252;
          font-size: 16px;
          font-weight: 300;
          display: inline-block;
          float: left;
          cursor: pointer;
          min-width: 100px;
          text-align: center;
          text-decoration: none; }
          .DesktopApp .Nav ul.menu li a:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: #e4e8ea;
            transition: opacity ease-out 150ms, transform ease-out 100ms;
            opacity: 0;
            border-radius: 3px;
            transform: scale(0.9);
            z-index: -1; }
          .DesktopApp .Nav ul.menu li a:hover:before {
            opacity: 1;
            transition: opacity ease-out 150ms, transform ease-out 100ms;
            transform: scale(1); }
          .DesktopApp .Nav ul.menu li a.border {
            border: 1px solid #e4e8ea;
            border-radius: 3px;
            height: 30px;
            margin-left: 5px;
            padding: 4px 10px;
            font-size: 15px; }
          .DesktopApp .Nav ul.menu li a.highlighted {
            color: white;
            font-size: 15px; }
            .DesktopApp .Nav ul.menu li a.highlighted:before {
              transform: scale(1);
              opacity: 1;
              background: #5ad9e7;
              background: -moz-linear-gradient(left, #5ad9e7 0%, #01bbee 100%);
              background: -webkit-linear-gradient(left, #5ad9e7 0%, #01bbee 100%);
              background: linear-gradient(to right, #5ad9e7 0%, #01bbee 100%);
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5ad9e7', endColorstr='#01bbee', GradientType=1); }
            .DesktopApp .Nav ul.menu li a.highlighted:hover:before {
              opacity: 0.75; }
        .DesktopApp .Nav ul.menu li.current a {
          font-weight: bold; }
  .DesktopApp .Nav .hamburger {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    top: calc(50% - 8px);
    cursor: pointer;
    z-index: 1000; }
    .DesktopApp .Nav .hamburger:after {
      content: 'menu';
      position: absolute;
      color: #b2bcc1;
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      top: 0;
      right: 30px; }
    .DesktopApp .Nav .hamburger span {
      display: inline-block;
      position: relative;
      float: left;
      height: 2px;
      width: 100%;
      margin: 9px 0;
      background-color: #535252;
      transition: all ease-out 250ms;
      -moz-transition: all ease-out 250ms;
      -webkit-transition: all ease-out 250ms; }
      .DesktopApp .Nav .hamburger span:before, .DesktopApp .Nav .hamburger span:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        background-color: #535252;
        transition: all ease-out 250ms;
        -moz-transition: all ease-out 250ms;
        -webkit-transition: all ease-out 250ms;
        transform-origin: center center; }
      .DesktopApp .Nav .hamburger span:before {
        top: -6px; }
      .DesktopApp .Nav .hamburger span:after {
        bottom: -6px; }

@media (min-width: 769px) {
  .DesktopApp .hamburger {
    display: none; } }

@media (max-width: 991px) {
  .DesktopApp .Nav .logo-wrapper .hide {
    display: none; } }

@media (max-width: 768px) {
  .DesktopApp .Nav .hamburger {
    display: inline-block; }
  .DesktopApp .Nav ul {
    visibility: hidden;
    opacity: 0; }
    .DesktopApp .Nav ul.menu {
      height: auto;
      margin: 0;
      position: fixed;
      width: 200px;
      background-color: rgba(255, 255, 255, 0.95);
      right: 0;
      top: 5px;
      padding: 60px 20px 20px 20px;
      border-left: 1px solid #e4e8ea;
      border-bottom: 1px solid #e4e8ea;
      border-radius: 0 0 0 3px;
      box-shadow: 0 2px 8px rgba(80, 80, 80, 0.05); }
      .DesktopApp .Nav ul.menu li {
        width: 100%;
        margin: 10px 0;
        height: auto; }
        .DesktopApp .Nav ul.menu li span, .DesktopApp .Nav ul.menu li a {
          width: 100%;
          margin-left: 0 !important;
          margin-right: 0 !important; }
        .DesktopApp .Nav ul.menu li span {
          display: none; }
  .DesktopApp .Nav.menu-open .hamburger {
    z-index: 999; }
    .DesktopApp .Nav.menu-open .hamburger span {
      background-color: transparent;
      opacity: 0.75; }
      .DesktopApp .Nav.menu-open .hamburger span:before {
        transform: rotate(45deg) translate(4px, 4px) !important; }
      .DesktopApp .Nav.menu-open .hamburger span:after {
        transform: rotate(-45deg) translate(4.25px, -4.75px) !important; }
    .DesktopApp .Nav.menu-open .hamburger:after {
      content: 'close'; }
    .DesktopApp .Nav.menu-open .hamburger:hover span {
      opacity: 1; }
  .DesktopApp .Nav.menu-open ul {
    visibility: visible;
    opacity: 1;
    transition: opacity ease-out 250ms, transform ease-out 250ms, visibility 250ms;
    transform: scale(1); } }
