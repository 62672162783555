.Logo {
  display: inline-block;
  position: relative;
  height: 60px;
  width: 240px;
  background: url("/images/logo.png");
  background-position: -5px 0;
  background-repeat: no-repeat;
  background-size: 240px; }

.Panel .Logo {
  width: 55px;
  margin-bottom: 15px; }

@media (max-width: 400px) {
  .Logo {
    display: inline-block;
    position: relative;
    height: 50px;
    width: 200px;
    background-size: 200px;
    margin-top: 5px; }
  .Panel .Logo {
    width: 45px;
    margin-bottom: 15px; } }
