.DesktopApp section {
  position: relative;
  display: inline-block;
  float: left;
  width: 100%; }
  .DesktopApp section h2 {
    position: relative;
    display: inline-block;
    float: left;
    width: 100%;
    line-height: 40px;
    font-size: 36px;
    font-weight: 300;
    text-align: center;
    color: #535252;
    margin: 0 0 30px 0; }
  .DesktopApp section p {
    text-align: inherit; }
