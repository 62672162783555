input.Checkbox {
  width: 16px;
  height: 16px;
  margin: 10px 0;
  float: left; }
  input.Checkbox + label {
    width: calc(100% - 30px);
    float: right;
    text-transform: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px; }
