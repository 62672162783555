.PageTitle {
  display: inline-block;
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 30px; }
  .PageTitle h2 {
    margin: 0 !important; }
  .PageTitle .Button {
    position: absolute;
    left: 0;
    top: 0; }
