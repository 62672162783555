input.Input {
  display: inline-block;
  float: left;
  position: relative;
  height: 36px;
  line-height: 20px;
  width: 100%;
  padding: 8px 15px;
  border-radius: 4px;
  border: 1px solid #e4e8ea;
  font-size: 14px; }
  input.Input:focus {
    outline: none; }

.DesktopApp input {
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(80, 80, 80, 0.05);
  font-family: 'Raleway', sans-serif;
  margin: 10px 0; }
  .DesktopApp input.Input {
    transition: border ease-out 150ms; }
    .DesktopApp input.Input:hover, .DesktopApp input.Input:focus {
      transition: border ease-out 150ms; }
    .DesktopApp input.Input:hover {
      border-bottom: 1px solid #5ad9e7; }
    .DesktopApp input.Input:focus {
      border-color: #5ad9e7;
      box-shadow: 0 2px 6px rgba(80, 80, 80, 0.15); }

.MobileApp input {
  font-family: 'Titilium Web', sans-serif; }
