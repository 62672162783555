.fullscreen-loader {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 100%;
  z-index: 99999;
  cursor: waiter; }

.loader {
  opacity: 1;
  margin: 0 auto;
  width: 60px;
  height: 10px;
  float: none;
  position: relative;
  animation: fadeIn .8s; }
  .loader div {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #b2bcc1;
    border-radius: 100%;
    display: inline-block;
    float: left;
    animation: bounce 1.4s infinite ease-in-out both; }
    .loader div.one {
      animation-delay: -0.32s !important; }
    .loader div.two {
      animation-delay: -0.16s !important; }

.Page .loader:not(.inline) {
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%; }

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

@keyframes fadeIn {
  0%, 50% {
    opacity: 0; }
  100% {
    opacity: 1; } }
