.Dialog-backdrop {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000; }
  .Dialog-backdrop .Dialog {
    overflow-y: auto;
    max-height: 100vh;
    top: 50%;
    left: 0;
    right: 0;
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    min-width: 300px;
    max-width: 600px;
    background-color: white;
    border-radius: 5px;
    padding: 30px;
    box-shadow: 0 1px 2px 1px rgba(80, 80, 80, 0.05);
    transform: translateY(-50%);
    animation: fade-in 250ms ease-in-out forwards; }
    .Dialog-backdrop .Dialog .PanelHeading, .Dialog-backdrop .Dialog p {
      text-align: center; }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
