.DesktopApp section article {
  position: relative;
  display: inline-block;
  float: left;
  width: 100%; }
  .DesktopApp section article h2 {
    position: relative;
    display: inline-block;
    float: left;
    text-align: center;
    width: 100%;
    line-height: 40px;
    font-size: 36px;
    font-weight: 300;
    color: #535252;
    margin: 30px 0;
    padding: 0 20px; }

.DesktopApp section aside + article {
  width: calc(100% - 355px); }

@media (max-width: 480px) {
  .DesktopApp article h2 {
    line-height: 30px;
    font-size: 28px; } }
