.Divider {
  display: inline-block;
  float: left;
  width: 100%; }
  .Divider span {
    background-color: #e4e8ea;
    display: inline-block;
    float: left;
    width: 100%;
    height: 1px; }

.MobileApp .Divider {
  padding: 16px;
  height: 33px; }

.DesktopApp .Divider {
  height: 61px;
  padding: 30px 0; }

.DesktopApp .Panel .Divider, .DesktopApp .Dialog .Divider {
  padding: 5px 0;
  height: 11px; }
