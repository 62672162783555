.AuthenticateDialog {
  text-align: center;
  max-width: 400px !important; }
  .AuthenticateDialog .back {
    position: absolute;
    left: -7px;
    top: -15px; }
  .AuthenticateDialog .close {
    position: absolute;
    right: -7px;
    top: -15px; }
  .AuthenticateDialog .notification {
    margin: 40px 0 10px; }
