.DesktopApp .PanelHeading {
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  min-height: 30px;
  position: relative; }
  .DesktopApp .PanelHeading.has-button-left {
    padding-left: 30px; }
  .DesktopApp .PanelHeading.has-button-right {
    padding-right: 30px; }
  .DesktopApp .PanelHeading h2 {
    font-size: 28px;
    line-height: 30px;
    color: #535252;
    margin: 0 !important;
    font-weight: 400;
    text-align: left;
    padding: 0; }
    .DesktopApp .PanelHeading h2.center {
      text-align: center; }
    .DesktopApp .PanelHeading h2 strong {
      font-weight: 600; }
  .DesktopApp .PanelHeading .Button {
    position: absolute;
    top: -5px; }
    .DesktopApp .PanelHeading .Button.right {
      right: -5px; }
    .DesktopApp .PanelHeading .Button.left {
      left: -5px; }
