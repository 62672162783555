.DesktopApp .Button {
  display: inline-block;
  border: none;
  height: 36px;
  width: auto;
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: opacity ease-out 150ms;
  border-radius: 3px;
  user-select: none; }
  .DesktopApp .Button:disabled {
    opacity: .25;
    cursor: not-allowed; }
  .DesktopApp .Button.left {
    float: left;
    margin-right: 10px; }
  .DesktopApp .Button.right {
    float: right;
    margin-left: 10px; }
  .DesktopApp .Button.centered {
    float: none;
    margin-left: auto;
    margin-right: auto; }
  .DesktopApp .Button.full {
    width: 100%; }
  .DesktopApp .Button.half {
    width: calc(50% - 10px); }
  .DesktopApp .Button:focus {
    outline: none; }
  .DesktopApp .Button.primary, .DesktopApp .Button.secondary, .DesktopApp .Button.danger {
    box-shadow: 0 2px 8px rgba(80, 80, 80, 0.2);
    padding: 8px 20px;
    font-weight: 400;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    line-height: 20px; }
    .DesktopApp .Button.primary[class*="ion"], .DesktopApp .Button.secondary[class*="ion"], .DesktopApp .Button.danger[class*="ion"] {
      text-align: left;
      padding-left: 36px;
      position: relative; }
      .DesktopApp .Button.primary[class*="ion"]:before, .DesktopApp .Button.secondary[class*="ion"]:before, .DesktopApp .Button.danger[class*="ion"]:before {
        position: absolute;
        height: 20px;
        width: 20px;
        line-height: 20px;
        text-align: center;
        left: 8px;
        top: 8px;
        font-size: 22px; }
  .DesktopApp .Button.primary:after, .DesktopApp .Button.secondary:after, .DesktopApp .Button.danger:after, .DesktopApp .Button.icon-squared:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    opacity: 0;
    transition: opacity ease-out 150ms; }
  .DesktopApp .Button.primary {
    background: #5ad9e7;
    background: -moz-linear-gradient(left, #5ad9e7 0%, #01bbee 100%);
    background: -webkit-linear-gradient(left, #5ad9e7 0%, #01bbee 100%);
    background: linear-gradient(to right, #5ad9e7 0%, #01bbee 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5ad9e7', endColorstr='#01bbee', GradientType=1);
    color: white; }
  .DesktopApp .Button.secondary {
    background-color: white;
    color: #535252; }
  .DesktopApp .Button.danger {
    background-color: #E25425;
    color: white; }
  .DesktopApp .Button.fab {
    position: fixed;
    right: 30px;
    bottom: 35px;
    box-shadow: 0 3px 10px rgba(80, 80, 80, 0.3);
    border-radius: 50%;
    background-color: white;
    padding: 5px;
    line-height: 40px;
    width: 50px;
    height: 50px;
    font-size: 24px;
    z-index: 1001;
    color: #b2bcc1; }
    .DesktopApp .Button.fab:after {
      display: none; }
    .DesktopApp .Button.fab:hover {
      color: #535252; }
  .DesktopApp .Button.link {
    background-color: transparent;
    padding: 0 5px;
    line-height: 20px;
    font-size: 16px;
    color: #535252; }
    .DesktopApp .Button.link:hover {
      text-decoration: underline;
      color: #5ad9e7; }
  .DesktopApp .Button.icon {
    background-color: transparent;
    border-radius: 20px;
    padding: 5px 0;
    width: 20px;
    height: 40px;
    line-height: 30px;
    font-size: 28px;
    color: #777e80;
    box-shadow: none; }
    .DesktopApp .Button.icon.left {
      text-align: left; }
    .DesktopApp .Button.icon.right {
      text-align: right; }
    .DesktopApp .Button.icon:hover {
      color: #535252; }
    .DesktopApp .Button.icon:after {
      display: none; }
  .DesktopApp .Button:hover:not(:disabled):after {
    opacity: 1;
    transition: opacity ease-out 150ms; }
  .DesktopApp .Button:active:not(:disabled) {
    transform: translateY(1px);
    transition: transform ease-out 150ms; }
  .DesktopApp .Button.icon-squared {
    height: 36px;
    width: 36px;
    border-radius: 3px;
    padding: 8px;
    line-height: 20px;
    box-shadow: 0 2px 8px rgba(80, 80, 80, 0.2);
    text-align: center;
    background-color: #5ad9e7;
    font-size: 18px;
    color: white; }
    .DesktopApp .Button.icon-squared.left {
      float: left;
      margin-right: 10px; }
    .DesktopApp .Button.icon-squared.right {
      float: right;
      margin-left: 10px; }

.DesktopApp ul.panel-list li .Button {
  height: 24px;
  padding: 2px 10px;
  font-size: 12px; }

.MobileApp .Button {
  display: inline-block;
  border: none;
  height: 36px;
  width: auto;
  padding: 8px 16px;
  line-height: 20px;
  font-weight: 400;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(80, 80, 80, 0.2);
  text-align: center;
  font-size: 15px;
  user-select: none;
  -webkit-tap-highlight-color: transparent; }
  .MobileApp .Button.primary {
    background-color: #5ad9e7;
    color: white; }
  .MobileApp .Button.secondary {
    background-color: white;
    color: #535252; }
  .MobileApp .Button.danger {
    background-color: #E25425;
    color: white; }
  .MobileApp .Button:disabled {
    opacity: .25;
    cursor: not-allowed; }
  .MobileApp .Button.left {
    float: left; }
  .MobileApp .Button.right {
    float: right; }
  .MobileApp .Button.centered {
    float: none;
    margin-left: auto;
    margin-right: auto; }
  .MobileApp .Button.full {
    width: 100%; }
  .MobileApp .Button.half {
    width: calc(50% - 10px); }
  .MobileApp .Button:active:not(:disabled), .MobileApp .Button:focus:not(:disabled), .MobileApp .Button:hover:not(:disabled) {
    outline: none;
    opacity: 0.75; }
  .MobileApp .Button:active:not(:disabled) {
    transform: translateY(1px);
    transition: transform ease-out 150ms; }
  .MobileApp .Button.icon-squared {
    height: 36px;
    width: 36px;
    border-radius: 3px;
    padding: 8px;
    line-height: 20px;
    box-shadow: 0 2px 8px rgba(80, 80, 80, 0.2);
    text-align: center;
    background-color: #5ad9e7;
    font-size: 18px;
    color: white; }
    .MobileApp .Button.icon-squared.left {
      float: left;
      margin-right: 3px; }
    .MobileApp .Button.icon-squared.right {
      float: right;
      margin-left: 3px; }
  .MobileApp .Button.link {
    box-shadow: none;
    background-color: transparent;
    padding: 0 5px;
    line-height: 20px;
    font-size: 16px;
    color: #535252; }
    .MobileApp .Button.link:hover {
      text-decoration: underline;
      color: #5ad9e7; }
