.MobileApp .Page {
  display: inline-block;
  float: left;
  width: 100%; }
  .MobileApp .Page.moment-show, .MobileApp .Page.moment-edit, .MobileApp .Page.moment-create, .MobileApp .Page.image-edit, .MobileApp .Page.image-select, .MobileApp .Page.maps-show, .MobileApp .Page.login, .MobileApp .Page.register {
    width: 100%;
    float: left;
    display: inline-block;
    background-color: white;
    min-height: calc(100vh - 40px); }
  .MobileApp .Page.image-select {
    padding-top: 8px; }
  .MobileApp .Page.moment-edit .ImageSelectHeader, .MobileApp .Page.moment-create .ImageSelectHeader {
    margin-bottom: -90px; }
  .MobileApp .Page.image-edit .MapsEdit {
    margin-bottom: -45px; }
  .MobileApp .Page.moment-edit .Wrapper.image-wrapper, .MobileApp .Page.moment-create .Wrapper.image-wrapper {
    margin-bottom: 10px; }
  .MobileApp .Page.moment-edit .Container, .MobileApp .Page.moment-create .Container {
    margin-bottom: 100px; }
  .MobileApp .Page.moment-create {
    padding-top: 15px; }

.DesktopApp .Page {
  display: inline-block;
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 380px);
  padding: 0 0 30px 0;
  animation: FadeIn;
  animation-duration: 250ms;
  animation-direction: forward; }
  .DesktopApp .Page .linkfooter {
    position: absolute;
    height: 40px;
    padding: 10px 0;
    line-height: 20px;
    text-align: center;
    left: 0;
    bottom: 0;
    width: 100%; }
    .DesktopApp .Page .linkfooter a {
      line-height: 20px;
      font-size: 12px;
      color: #777e80; }
      .DesktopApp .Page .linkfooter a:hover {
        text-decoration: underline; }
  .DesktopApp .Page.home {
    display: inline-block;
    width: 100%;
    float: left;
    background-color: white;
    text-align: center; }
  .DesktopApp .Page.index-moments, .DesktopApp .Page.my-trips {
    display: inline-block;
    width: 100%;
    float: left;
    text-align: center;
    overflow: hidden; }
  .DesktopApp .Page.my-trips {
    padding: 90px 20px 20px 20px; }
  .DesktopApp .Page.photoalbum-explanation {
    padding: 20px; }
  .DesktopApp .Page.index-moments {
    padding: 30px 20px 20px 20px; }
  .DesktopApp .Page.photoalbum-preview, .DesktopApp .Page.trip-create, .DesktopApp .Page.trip-edit, .DesktopApp .Page.moment-create, .DesktopApp .Page.moment-edit, .DesktopApp .Page.image-select, .DesktopApp .Page.image-edit, .DesktopApp .Page.error-page {
    display: inline-block;
    text-align: center;
    padding-top: 90px; }
  .DesktopApp .Page.login, .DesktopApp .Page.register {
    padding: 90px 0 0 0; }
  .DesktopApp .Page.my-account, .DesktopApp .Page.recover-password, .DesktopApp .Page.reset-password {
    padding: 30px 0; }
  .DesktopApp .Page.my-account, .DesktopApp .Page.recover-password, .DesktopApp .Page.reset-password, .DesktopApp .Page.login, .DesktopApp .Page.register {
    display: inline-block;
    width: 100%;
    float: left;
    text-align: center;
    position: relative; }
    .DesktopApp .Page.my-account:before, .DesktopApp .Page.recover-password:before, .DesktopApp .Page.reset-password:before, .DesktopApp .Page.login:before, .DesktopApp .Page.register:before {
      content: '';
      position: absolute;
      background-color: #f4f6f7;
      border-bottom: 1px solid #e4e8ea;
      height: 180px;
      width: 100%;
      left: 0;
      top: 0; }
  .DesktopApp .Page.trip-show {
    padding: 80px 20px 0 20px; }
  .DesktopApp .Page.my-trips, .DesktopApp .Page.trip-show, .DesktopApp .Page.photoalbum-explanation {
    text-align: center; }
    .DesktopApp .Page.my-trips h2, .DesktopApp .Page.trip-show h2, .DesktopApp .Page.photoalbum-explanation h2 {
      margin-bottom: 30px; }
  .DesktopApp .Page.moment-show {
    padding: 80px 20px 20px 20px;
    width: 70%; }
  .DesktopApp .Page.trip-edit .Container, .DesktopApp .Page.moment-create .Container {
    margin-bottom: 105px; }

@media (max-width: 640px) {
  .DesktopApp .Page.index-moments, .DesktopApp .Page.my-trips, .DesktopApp .Page.moment-show, .DesktopApp .Page.trip-show {
    padding-left: 0;
    padding-right: 0; }
  .DesktopApp .Page.moment-show, .DesktopApp .Page.trip-show {
    padding-top: 0; }
  .DesktopApp .Page.my-account:before {
    height: 120px; } }

@media (max-width: 991px) {
  .DesktopApp .Page.moment-show {
    width: 100%; } }
