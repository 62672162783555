.DesktopApp .Panel {
  width: 100%;
  display: inline-block;
  float: none;
  text-align: left;
  max-width: 640px;
  position: relative;
  background-color: white;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #e4e8ea; }
  .DesktopApp .Panel.gray {
    background-color: #f9f9fa; }
  .DesktopApp .Panel.no-border {
    border: none; }
  .DesktopApp .Panel.no-padding {
    padding: 0; }
  .DesktopApp .Panel.center {
    text-align: center; }

.DesktopApp .Page.trip-show .Panel {
  cursor: pointer; }

.DesktopApp .Page.login .Panel {
  width: 340px;
  text-align: center;
  padding: 30px 45px; }

.DesktopApp .Page.register .Panel, .DesktopApp .Page.recover-password .Panel, .DesktopApp .Page.reset-password .Panel {
  width: 450px;
  text-align: center;
  padding: 30px 45px; }

@media (max-width: 640px) {
  .DesktopApp .Panel {
    max-width: 100vw;
    padding: 30px !important;
    margin-left: -15px;
    margin-right: -15px;
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 6px rgba(80, 80, 80, 0.2);
    width: calc(100% + 30px); } }
