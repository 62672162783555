.Container {
  margin-left: auto;
  margin-right: auto;
  clear: both;
  max-width: 100%;
  position: relative; }

@media (max-width: 767px) {
  .DesktopApp .Container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px; } }

@media (min-width: 768px) {
  .DesktopApp .Container {
    width: 750px; } }

@media (min-width: 992px) {
  .DesktopApp .Container {
    width: 970px; } }

@media (min-width: 1200px) {
  .DesktopApp .Container {
    width: 1170px; } }
