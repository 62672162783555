.MobileApp .Wrapper {
  display: inline-block;
  float: left;
  width: 100%; }
  .MobileApp .Wrapper.center {
    text-align: center; }
  .MobileApp .Wrapper.right {
    text-align: right; }
  .MobileApp .Wrapper.left {
    text-align: left; }
  .MobileApp .Wrapper.form-group {
    padding: 0 16px;
    margin: 0 0 16px 0; }
  .MobileApp .Wrapper.button-wrapper {
    padding: 0 16px;
    margin: 16px 0 0; }
  .MobileApp .Wrapper.text {
    padding: 0 16px; }
    .MobileApp .Wrapper.text p {
      white-space: pre-line;
      position: relative; }
  .MobileApp .Wrapper.empty {
    margin-top: 100px; }
  .MobileApp .Wrapper.image-wrapper {
    padding: 4px 12px; }

.DesktopApp .Wrapper {
  display: inline-block;
  float: left;
  width: 100%; }
  .DesktopApp .Wrapper.center {
    text-align: center; }
    .DesktopApp .Wrapper.center p {
      text-align: center; }
  .DesktopApp .Wrapper.right {
    text-align: right; }
  .DesktopApp .Wrapper.left {
    text-align: left; }
  .DesktopApp .Wrapper.form-group {
    padding: 0;
    margin: 0 0 20px 0;
    position: relative; }
  .DesktopApp .Wrapper.button-wrapper {
    padding: 0;
    margin: 20px 0 0; }
  .DesktopApp .Wrapper.text {
    padding: 15px 0; }
    .DesktopApp .Wrapper.text p {
      white-space: pre-line; }
  .DesktopApp .Wrapper.empty {
    margin-top: 100px; }
  .DesktopApp .Wrapper.moments-wrapper, .DesktopApp .Wrapper.trips-wrapper {
    min-height: 200px;
    float: none;
    display: inline-block; }
  .DesktopApp .Wrapper.moments-wrapper {
    margin: 0 -2px;
    width: calc(100% + 4px); }
  .DesktopApp .Wrapper.trips-wrapper {
    margin: 0 -5px;
    width: calc(100% + 10px); }
  .DesktopApp .Wrapper.trip-moments-wrapper {
    width: 50%;
    margin: 0 auto;
    min-width: 480px;
    max-width: 768px;
    float: none; }
  .DesktopApp .Wrapper.moment-detail-wrapper {
    float: left;
    width: 100%;
    position: relative; }

.DesktopApp .MomentListItem .Wrapper.image-wrapper,
.DesktopApp .TripMomentItem .Wrapper.image-wrapper {
  overflow: hidden;
  position: relative;
  padding: 0;
  display: inline-block;
  float: left;
  width: 100%; }

.DesktopApp .trip-create .Panel .Wrapper.image-wrapper, .DesktopApp .trip-create .Dialog .Wrapper.image-wrapper,
.DesktopApp .moment-create .Panel .Wrapper.image-wrapper,
.DesktopApp .moment-create .Dialog .Wrapper.image-wrapper,
.DesktopApp .moment-edit .Panel .Wrapper.image-wrapper,
.DesktopApp .moment-edit .Dialog .Wrapper.image-wrapper,
.DesktopApp .image-select .Panel .Wrapper.image-wrapper,
.DesktopApp .image-select .Dialog .Wrapper.image-wrapper {
  margin: 10px 0 50px; }

.DesktopApp .image-select .Wrapper.image-wrapper {
  margin-left: -2px;
  margin-right: -2px;
  width: calc(100% + 4px); }

@media (max-width: 1024px) {
  .DesktopApp .Wrapper.trip-moments-wrapper {
    width: 75%; } }

@media (max-width: 640px) {
  .DesktopApp .Wrapper.trip-moments-wrapper {
    min-width: inherit;
    width: 100%; }
  .DesktopApp .Wrapper.moment-detail-wrapper p {
    padding: 0 15px;
    margin-bottom: 30px !important; } }
