@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,600);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,700);
.AuthenticateDialog {
  text-align: center;
  max-width: 400px !important; }
  .AuthenticateDialog .back {
    position: absolute;
    left: -7px;
    top: -15px; }
  .AuthenticateDialog .close {
    position: absolute;
    right: -7px;
    top: -15px; }
  .AuthenticateDialog .notification {
    margin: 40px 0 10px; }

.Dialog-backdrop {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000; }
  .Dialog-backdrop .Dialog {
    overflow-y: auto;
    max-height: 100vh;
    top: 50%;
    left: 0;
    right: 0;
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    min-width: 300px;
    max-width: 600px;
    background-color: white;
    border-radius: 5px;
    padding: 30px;
    box-shadow: 0 1px 2px 1px rgba(80, 80, 80, 0.05);
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-animation: fade-in 250ms ease-in-out forwards;
            animation: fade-in 250ms ease-in-out forwards; }
    .Dialog-backdrop .Dialog .PanelHeading, .Dialog-backdrop .Dialog p {
      text-align: center; }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.MobileApp .Wrapper {
  display: inline-block;
  float: left;
  width: 100%; }
  .MobileApp .Wrapper.center {
    text-align: center; }
  .MobileApp .Wrapper.right {
    text-align: right; }
  .MobileApp .Wrapper.left {
    text-align: left; }
  .MobileApp .Wrapper.form-group {
    padding: 0 16px;
    margin: 0 0 16px 0; }
  .MobileApp .Wrapper.button-wrapper {
    padding: 0 16px;
    margin: 16px 0 0; }
  .MobileApp .Wrapper.text {
    padding: 0 16px; }
    .MobileApp .Wrapper.text p {
      white-space: pre-line;
      position: relative; }
  .MobileApp .Wrapper.empty {
    margin-top: 100px; }
  .MobileApp .Wrapper.image-wrapper {
    padding: 4px 12px; }

.DesktopApp .Wrapper {
  display: inline-block;
  float: left;
  width: 100%; }
  .DesktopApp .Wrapper.center {
    text-align: center; }
    .DesktopApp .Wrapper.center p {
      text-align: center; }
  .DesktopApp .Wrapper.right {
    text-align: right; }
  .DesktopApp .Wrapper.left {
    text-align: left; }
  .DesktopApp .Wrapper.form-group {
    padding: 0;
    margin: 0 0 20px 0;
    position: relative; }
  .DesktopApp .Wrapper.button-wrapper {
    padding: 0;
    margin: 20px 0 0; }
  .DesktopApp .Wrapper.text {
    padding: 15px 0; }
    .DesktopApp .Wrapper.text p {
      white-space: pre-line; }
  .DesktopApp .Wrapper.empty {
    margin-top: 100px; }
  .DesktopApp .Wrapper.moments-wrapper, .DesktopApp .Wrapper.trips-wrapper {
    min-height: 200px;
    float: none;
    display: inline-block; }
  .DesktopApp .Wrapper.moments-wrapper {
    margin: 0 -2px;
    width: calc(100% + 4px); }
  .DesktopApp .Wrapper.trips-wrapper {
    margin: 0 -5px;
    width: calc(100% + 10px); }
  .DesktopApp .Wrapper.trip-moments-wrapper {
    width: 50%;
    margin: 0 auto;
    min-width: 480px;
    max-width: 768px;
    float: none; }
  .DesktopApp .Wrapper.moment-detail-wrapper {
    float: left;
    width: 100%;
    position: relative; }

.DesktopApp .MomentListItem .Wrapper.image-wrapper,
.DesktopApp .TripMomentItem .Wrapper.image-wrapper {
  overflow: hidden;
  position: relative;
  padding: 0;
  display: inline-block;
  float: left;
  width: 100%; }

.DesktopApp .trip-create .Panel .Wrapper.image-wrapper, .DesktopApp .trip-create .Dialog .Wrapper.image-wrapper,
.DesktopApp .moment-create .Panel .Wrapper.image-wrapper,
.DesktopApp .moment-create .Dialog .Wrapper.image-wrapper,
.DesktopApp .moment-edit .Panel .Wrapper.image-wrapper,
.DesktopApp .moment-edit .Dialog .Wrapper.image-wrapper,
.DesktopApp .image-select .Panel .Wrapper.image-wrapper,
.DesktopApp .image-select .Dialog .Wrapper.image-wrapper {
  margin: 10px 0 50px; }

.DesktopApp .image-select .Wrapper.image-wrapper {
  margin-left: -2px;
  margin-right: -2px;
  width: calc(100% + 4px); }

@media (max-width: 1024px) {
  .DesktopApp .Wrapper.trip-moments-wrapper {
    width: 75%; } }

@media (max-width: 640px) {
  .DesktopApp .Wrapper.trip-moments-wrapper {
    min-width: inherit;
    width: 100%; }
  .DesktopApp .Wrapper.moment-detail-wrapper p {
    padding: 0 15px;
    margin-bottom: 30px !important; } }

input.Input {
  display: inline-block;
  float: left;
  position: relative;
  height: 36px;
  line-height: 20px;
  width: 100%;
  padding: 8px 15px;
  border-radius: 4px;
  border: 1px solid #e4e8ea;
  font-size: 14px; }
  input.Input:focus {
    outline: none; }

.DesktopApp input {
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(80, 80, 80, 0.05);
  font-family: 'Raleway', sans-serif;
  margin: 10px 0; }
  .DesktopApp input.Input {
    -webkit-transition: border ease-out 150ms;
    transition: border ease-out 150ms; }
    .DesktopApp input.Input:hover, .DesktopApp input.Input:focus {
      -webkit-transition: border ease-out 150ms;
      transition: border ease-out 150ms; }
    .DesktopApp input.Input:hover {
      border-bottom: 1px solid #5ad9e7; }
    .DesktopApp input.Input:focus {
      border-color: #5ad9e7;
      box-shadow: 0 2px 6px rgba(80, 80, 80, 0.15); }

.MobileApp input {
  font-family: 'Titilium Web', sans-serif; }

.Label {
  display: inline-block;
  float: left;
  position: relative;
  width: 100%;
  min-height: 15px;
  line-height: 15px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: left;
  color: #535252;
  font-weight: 600;
  margin-bottom: 5px; }
  .Label.danger {
    color: #E25425; }

.DesktopApp .Button {
  display: inline-block;
  border: none;
  height: 36px;
  width: auto;
  position: relative;
  text-align: center;
  cursor: pointer;
  -webkit-transition: opacity ease-out 150ms;
  transition: opacity ease-out 150ms;
  border-radius: 3px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .DesktopApp .Button:disabled {
    opacity: .25;
    cursor: not-allowed; }
  .DesktopApp .Button.left {
    float: left;
    margin-right: 10px; }
  .DesktopApp .Button.right {
    float: right;
    margin-left: 10px; }
  .DesktopApp .Button.centered {
    float: none;
    margin-left: auto;
    margin-right: auto; }
  .DesktopApp .Button.full {
    width: 100%; }
  .DesktopApp .Button.half {
    width: calc(50% - 10px); }
  .DesktopApp .Button:focus {
    outline: none; }
  .DesktopApp .Button.primary, .DesktopApp .Button.secondary, .DesktopApp .Button.danger {
    box-shadow: 0 2px 8px rgba(80, 80, 80, 0.2);
    padding: 8px 20px;
    font-weight: 400;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    line-height: 20px; }
    .DesktopApp .Button.primary[class*="ion"], .DesktopApp .Button.secondary[class*="ion"], .DesktopApp .Button.danger[class*="ion"] {
      text-align: left;
      padding-left: 36px;
      position: relative; }
      .DesktopApp .Button.primary[class*="ion"]:before, .DesktopApp .Button.secondary[class*="ion"]:before, .DesktopApp .Button.danger[class*="ion"]:before {
        position: absolute;
        height: 20px;
        width: 20px;
        line-height: 20px;
        text-align: center;
        left: 8px;
        top: 8px;
        font-size: 22px; }
  .DesktopApp .Button.primary:after, .DesktopApp .Button.secondary:after, .DesktopApp .Button.danger:after, .DesktopApp .Button.icon-squared:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    opacity: 0;
    -webkit-transition: opacity ease-out 150ms;
    transition: opacity ease-out 150ms; }
  .DesktopApp .Button.primary {
    background: #5ad9e7;
    background: -webkit-gradient(linear, left top, right top, from(#5ad9e7), to(#01bbee));
    background: linear-gradient(to right, #5ad9e7 0%, #01bbee 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5ad9e7', endColorstr='#01bbee', GradientType=1);
    color: white; }
  .DesktopApp .Button.secondary {
    background-color: white;
    color: #535252; }
  .DesktopApp .Button.danger {
    background-color: #E25425;
    color: white; }
  .DesktopApp .Button.fab {
    position: fixed;
    right: 30px;
    bottom: 35px;
    box-shadow: 0 3px 10px rgba(80, 80, 80, 0.3);
    border-radius: 50%;
    background-color: white;
    padding: 5px;
    line-height: 40px;
    width: 50px;
    height: 50px;
    font-size: 24px;
    z-index: 1001;
    color: #b2bcc1; }
    .DesktopApp .Button.fab:after {
      display: none; }
    .DesktopApp .Button.fab:hover {
      color: #535252; }
  .DesktopApp .Button.link {
    background-color: transparent;
    padding: 0 5px;
    line-height: 20px;
    font-size: 16px;
    color: #535252; }
    .DesktopApp .Button.link:hover {
      text-decoration: underline;
      color: #5ad9e7; }
  .DesktopApp .Button.icon {
    background-color: transparent;
    border-radius: 20px;
    padding: 5px 0;
    width: 20px;
    height: 40px;
    line-height: 30px;
    font-size: 28px;
    color: #777e80;
    box-shadow: none; }
    .DesktopApp .Button.icon.left {
      text-align: left; }
    .DesktopApp .Button.icon.right {
      text-align: right; }
    .DesktopApp .Button.icon:hover {
      color: #535252; }
    .DesktopApp .Button.icon:after {
      display: none; }
  .DesktopApp .Button:hover:not(:disabled):after {
    opacity: 1;
    -webkit-transition: opacity ease-out 150ms;
    transition: opacity ease-out 150ms; }
  .DesktopApp .Button:active:not(:disabled) {
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    -webkit-transition: -webkit-transform ease-out 150ms;
    transition: -webkit-transform ease-out 150ms;
    transition: transform ease-out 150ms;
    transition: transform ease-out 150ms, -webkit-transform ease-out 150ms; }
  .DesktopApp .Button.icon-squared {
    height: 36px;
    width: 36px;
    border-radius: 3px;
    padding: 8px;
    line-height: 20px;
    box-shadow: 0 2px 8px rgba(80, 80, 80, 0.2);
    text-align: center;
    background-color: #5ad9e7;
    font-size: 18px;
    color: white; }
    .DesktopApp .Button.icon-squared.left {
      float: left;
      margin-right: 10px; }
    .DesktopApp .Button.icon-squared.right {
      float: right;
      margin-left: 10px; }

.DesktopApp ul.panel-list li .Button {
  height: 24px;
  padding: 2px 10px;
  font-size: 12px; }

.MobileApp .Button {
  display: inline-block;
  border: none;
  height: 36px;
  width: auto;
  padding: 8px 16px;
  line-height: 20px;
  font-weight: 400;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(80, 80, 80, 0.2);
  text-align: center;
  font-size: 15px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent; }
  .MobileApp .Button.primary {
    background-color: #5ad9e7;
    color: white; }
  .MobileApp .Button.secondary {
    background-color: white;
    color: #535252; }
  .MobileApp .Button.danger {
    background-color: #E25425;
    color: white; }
  .MobileApp .Button:disabled {
    opacity: .25;
    cursor: not-allowed; }
  .MobileApp .Button.left {
    float: left; }
  .MobileApp .Button.right {
    float: right; }
  .MobileApp .Button.centered {
    float: none;
    margin-left: auto;
    margin-right: auto; }
  .MobileApp .Button.full {
    width: 100%; }
  .MobileApp .Button.half {
    width: calc(50% - 10px); }
  .MobileApp .Button:active:not(:disabled), .MobileApp .Button:focus:not(:disabled), .MobileApp .Button:hover:not(:disabled) {
    outline: none;
    opacity: 0.75; }
  .MobileApp .Button:active:not(:disabled) {
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
    -webkit-transition: -webkit-transform ease-out 150ms;
    transition: -webkit-transform ease-out 150ms;
    transition: transform ease-out 150ms;
    transition: transform ease-out 150ms, -webkit-transform ease-out 150ms; }
  .MobileApp .Button.icon-squared {
    height: 36px;
    width: 36px;
    border-radius: 3px;
    padding: 8px;
    line-height: 20px;
    box-shadow: 0 2px 8px rgba(80, 80, 80, 0.2);
    text-align: center;
    background-color: #5ad9e7;
    font-size: 18px;
    color: white; }
    .MobileApp .Button.icon-squared.left {
      float: left;
      margin-right: 3px; }
    .MobileApp .Button.icon-squared.right {
      float: right;
      margin-left: 3px; }
  .MobileApp .Button.link {
    box-shadow: none;
    background-color: transparent;
    padding: 0 5px;
    line-height: 20px;
    font-size: 16px;
    color: #535252; }
    .MobileApp .Button.link:hover {
      text-decoration: underline;
      color: #5ad9e7; }

.fullscreen-loader {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 100%;
  z-index: 99999;
  cursor: waiter; }

.loader {
  opacity: 1;
  margin: 0 auto;
  width: 60px;
  height: 10px;
  float: none;
  position: relative;
  -webkit-animation: fadeIn .8s;
          animation: fadeIn .8s; }
  .loader div {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #b2bcc1;
    border-radius: 100%;
    display: inline-block;
    float: left;
    -webkit-animation: bounce 1.4s infinite ease-in-out both;
            animation: bounce 1.4s infinite ease-in-out both; }
    .loader div.one {
      -webkit-animation-delay: -0.32s !important;
              animation-delay: -0.32s !important; }
    .loader div.two {
      -webkit-animation-delay: -0.16s !important;
              animation-delay: -0.16s !important; }

.Page .loader:not(.inline) {
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%; }

@-webkit-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes fadeIn {
  0%, 50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0%, 50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.DesktopApp .Panel {
  width: 100%;
  display: inline-block;
  float: none;
  text-align: left;
  max-width: 640px;
  position: relative;
  background-color: white;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #e4e8ea; }
  .DesktopApp .Panel.gray {
    background-color: #f9f9fa; }
  .DesktopApp .Panel.no-border {
    border: none; }
  .DesktopApp .Panel.no-padding {
    padding: 0; }
  .DesktopApp .Panel.center {
    text-align: center; }

.DesktopApp .Page.trip-show .Panel {
  cursor: pointer; }

.DesktopApp .Page.login .Panel {
  width: 340px;
  text-align: center;
  padding: 30px 45px; }

.DesktopApp .Page.register .Panel, .DesktopApp .Page.recover-password .Panel, .DesktopApp .Page.reset-password .Panel {
  width: 450px;
  text-align: center;
  padding: 30px 45px; }

@media (max-width: 640px) {
  .DesktopApp .Panel {
    max-width: 100vw;
    padding: 30px !important;
    margin-left: -15px;
    margin-right: -15px;
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 6px rgba(80, 80, 80, 0.2);
    width: calc(100% + 30px); } }

.Logo {
  display: inline-block;
  position: relative;
  height: 60px;
  width: 240px;
  background: url("/images/logo.png");
  background-position: -5px 0;
  background-repeat: no-repeat;
  background-size: 240px; }

.Panel .Logo {
  width: 55px;
  margin-bottom: 15px; }

@media (max-width: 400px) {
  .Logo {
    display: inline-block;
    position: relative;
    height: 50px;
    width: 200px;
    background-size: 200px;
    margin-top: 5px; }
  .Panel .Logo {
    width: 45px;
    margin-bottom: 15px; } }

.DesktopApp .PanelHeading {
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  min-height: 30px;
  position: relative; }
  .DesktopApp .PanelHeading.has-button-left {
    padding-left: 30px; }
  .DesktopApp .PanelHeading.has-button-right {
    padding-right: 30px; }
  .DesktopApp .PanelHeading h2 {
    font-size: 28px;
    line-height: 30px;
    color: #535252;
    margin: 0 !important;
    font-weight: 400;
    text-align: left;
    padding: 0; }
    .DesktopApp .PanelHeading h2.center {
      text-align: center; }
    .DesktopApp .PanelHeading h2 strong {
      font-weight: 600; }
  .DesktopApp .PanelHeading .Button {
    position: absolute;
    top: -5px; }
    .DesktopApp .PanelHeading .Button.right {
      right: -5px; }
    .DesktopApp .PanelHeading .Button.left {
      left: -5px; }

.Divider {
  display: inline-block;
  float: left;
  width: 100%; }
  .Divider span {
    background-color: #e4e8ea;
    display: inline-block;
    float: left;
    width: 100%;
    height: 1px; }

.MobileApp .Divider {
  padding: 16px;
  height: 33px; }

.DesktopApp .Divider {
  height: 61px;
  padding: 30px 0; }

.DesktopApp .Panel .Divider, .DesktopApp .Dialog .Divider {
  padding: 5px 0;
  height: 11px; }

input.Checkbox {
  width: 16px;
  height: 16px;
  margin: 10px 0;
  float: left; }
  input.Checkbox + label {
    width: calc(100% - 30px);
    float: right;
    text-transform: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px; }

#auth-dialog-root {
  margin: 0;
  padding: 0; }

iframe {
  width: 100%;
  height: 100%;
  border: none;
  -webkit-animation: 2s ease 0s normal forwards 1 fadein;
          animation: 2s ease 0s normal forwards 1 fadein; }
  iframe.explanation-iframe {
    height: 1000px; }

body {
  padding: 0;
  margin: 0;
  font-size: 15px;
  color: #535252;
  width: 100%;
  height: 100%;
  /**
   * Beta
   */ }
  body .beta.mobile {
    position: fixed;
    top: 32.5px;
    left: calc(50% - 30px);
    z-index: 10000;
    width: 60px;
    height: 15px;
    line-height: 15px;
    color: #535252;
    font-size: 11px;
    text-align: center;
    background: #FDCF54;
    box-shadow: 0 0 10px rgba(80, 80, 80, 0.2);
    border-radius: 4px; }
  body .beta.desktop {
    position: fixed;
    top: 75px;
    right: 0;
    width: 100px;
    height: 100px;
    z-index: 999;
    overflow: hidden; }
    body .beta.desktop span {
      display: inline-block;
      width: 150px;
      line-height: 20px;
      background: #5ad9e7;
      color: white;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      -webkit-transform-origin: center;
              transform-origin: center;
      text-align: center;
      position: absolute;
      right: -40px;
      top: 25px;
      box-shadow: 0 0 10px rgba(80, 80, 80, 0.2); }
  body *, body ::after, body ::before {
    box-sizing: border-box; }
  body p {
    font-size: 14px;
    line-height: 25px;
    display: inline-block;
    float: left;
    width: 100%;
    color: #535252;
    font-weight: 300;
    margin: 0 0 10px 0; }

.MobileApp {
  position: relative;
  padding-top: 40px;
  display: inline-block;
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #f4f6f7;
  font-family: 'Titilium Web', sans-serif; }
  .MobileApp .visibility {
    height: 24px;
    width: 24px;
    background: #FDCF54;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.25);
    line-height: 24px;
    text-align: center;
    z-index: 999;
    font-size: 18px;
    box-shadow: 0 0 6px rgba(80, 80, 80, 0.2); }
  .MobileApp .loading {
    position: fixed;
    left: 0;
    top: 50px;
    right: 0;
    margin: 0 auto;
    width: 150px;
    text-align: center;
    line-height: 15px;
    padding: 5px 10px;
    color: white;
    font-size: 10px;
    z-index: 99999;
    box-shadow: 0 1px 2px 1px rgba(80, 80, 80, 0.1);
    border-radius: 3px;
    overflow: hidden; }
    .MobileApp .loading:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      background-color: #777e80;
      -webkit-animation: glow 2s linear infinite;
              animation: glow 2s linear infinite;
      z-index: -1; }

.DesktopApp {
  position: relative;
  display: inline-block;
  float: left;
  width: 100%;
  padding: 75px 0 0 0;
  font-family: 'Raleway', sans-serif; }
  .DesktopApp p {
    font-family: 'Roboto Slab', sans-serif;
    font-size: 15px;
    line-height: 20px;
    text-align: left; }
    .DesktopApp p.large {
      font-size: 16px;
      line-height: 28px;
      font-weight: 300; }
  .DesktopApp .loading {
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: transparent;
    z-index: 9999;
    font-size: 0; }
    .DesktopApp .loading:before {
      display: block;
      position: absolute;
      content: "";
      left: -200px;
      width: 200px;
      height: 5px;
      background-color: rgba(255, 255, 255, 0.5);
      -webkit-animation: loading 1s linear infinite;
              animation: loading 1s linear infinite; }

@-webkit-keyframes loading {
  from {
    left: -200px;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

@keyframes loading {
  from {
    left: -200px;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

ul.panel-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
  display: inline-block; }
  ul.panel-list li {
    width: 100%;
    float: left;
    display: inline-block;
    padding: 10px 0;
    border-top: 1px solid #e4e8ea;
    text-align: left; }
    ul.panel-list li span {
      line-height: 20px;
      margin: 2px 0;
      display: inline-block;
      float: left; }

.account-header {
  padding-top: 105px;
  padding-left: 200px;
  position: relative;
  max-width: 640px;
  min-height: 180px;
  width: 100%;
  float: none;
  margin: 60px auto 30px auto; }
  .account-header h2 {
    text-align: left !important;
    margin: 0 !important; }
  .account-header h3 {
    text-align: left !important;
    margin: 5px 0 0 0 !important;
    color: #777e80;
    font-weight: 300;
    float: left;
    font-style: italic;
    font-size: 14px; }
  .account-header .Avatar {
    position: absolute !important;
    left: 0;
    top: 0; }

.truncated {
  max-height: 40px;
  line-height: 20px;
  height: auto;
  width: 100%;
  float: left;
  display: inline-block;
  overflow: hidden;
  position: relative; }
  .truncated:before {
    content: '... See more';
    position: absolute;
    right: 0;
    bottom: 0;
    color: #b2bcc1;
    background: white;
    z-index: 10; }
  .truncated:after {
    content: '';
    position: absolute;
    right: 0;
    width: 72px;
    height: 20px;
    background: white;
    z-index: 11; }

.Pointer {
  color: #5ad9e7;
  font-size: 36px;
  text-shadow: 0 -2px 10px rgba(80, 80, 80, 0.25);
  -webkit-transform: translate(-10px, -36px);
          transform: translate(-10px, -36px); }

.columns {
  margin-left: -15px;
  margin-right: -15px; }
  .columns [class*='column-'] {
    padding: 0 15px;
    float: left; }
  .columns .column-1 {
    width: 100%; }
  .columns .column-2 {
    width: 50%; }
  .columns .column-3 {
    width: 33.33%; }
  .columns .column-4 {
    width: 24%; }

.fade-in {
  -webkit-animation: FadeIn;
          animation: FadeIn;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-direction: forward;
          animation-direction: forward;
  -webkit-transform-origin: center 0;
          transform-origin: center 0; }

@-webkit-keyframes FadeIn {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes FadeIn {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes SlideInBottom {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes SlideInBottom {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes Zoom {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes Zoom {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes glow {
  0% {
    opacity: 0.4; }
  50% {
    opacity: 0.7; }
  100% {
    opacity: 0.4; } }

@keyframes glow {
  0% {
    opacity: 0.4; }
  50% {
    opacity: 0.7; }
  100% {
    opacity: 0.4; } }

@keyframes loading {
  from {
    left: -200px;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

@-webkit-keyframes bounceUpDown {
  0% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  70% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px); } }

@keyframes bounceUpDown {
  0% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px); }
  70% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px); } }

@media (max-width: 640px) {
  .account-header {
    margin-top: 0;
    padding-top: 180px;
    padding-left: 0;
    padding-right: 0;
    display: inline-block; }
    .account-header h2, .account-header h3 {
      width: 100%;
      text-align: center !important; }
    .account-header .Avatar {
      left: 0;
      right: 0;
      margin: auto; } }

.notification {
  margin: 0 auto;
  display: block;
  width: 100%;
  text-align: center;
  padding: 21px;
  background: #E25425;
  color: white;
  z-index: 1000;
  border-radius: 4px; }
  .notification.top {
    border-radius: 0;
    position: absolute; }

.ReactModal__Overlay {
  z-index: 10000 !important; }
  .ReactModal__Overlay .ril-toolbar {
    background: none; }
    .ReactModal__Overlay .ril-toolbar .ril-zoom-out, .ReactModal__Overlay .ril-toolbar .ril-zoom-in {
      display: none; }
    .ReactModal__Overlay .ril-toolbar .ril__closeButton {
      background-color: rgba(0, 0, 0, 0.3) !important;
      height: 40px; }

@media (max-width: 767px) {
  .ReactModal__Overlay .ril__navButtons {
    display: none; } }

.validation {
  text-transform: none;
  display: inline-block;
  float: left;
  border-radius: 4px;
  margin-top: 5px;
  background-color: #ff8d8b;
  color: white;
  line-height: 20px;
  min-height: 20px;
  padding: 0 10px;
  position: relative;
  -webkit-animation-name: FadeIn;
          animation-name: FadeIn;
  -webkit-animation-duration: 1000ms;
          animation-duration: 1000ms;
  -webkit-animation-direction: forward;
          animation-direction: forward;
  opacity: 1;
  font-size: 12px;
  font-weight: 300; }
  .validation:after {
    position: absolute;
    content: " ";
    width: 0;
    height: 0;
    top: -3px;
    left: 15px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 3px solid #ff8d8b; }

.intro_info {
  margin: 10px 0 30px; }

.RecaptchaContainer {
  min-height: 78px; }

.DesktopApp .PageContainer {
  min-height: calc(100vh - 380px); }

footer {
  display: inline-block;
  width: 100%;
  float: left;
  height: 300px;
  border-top: 1px solid #e4e8ea; }
  footer.small {
    width: 70%; }

@media (max-width: 1214px) {
  footer {
    height: 345px; } }

@media (max-width: 991px) {
  footer.small {
    width: 100%; } }

@media (max-width: 768px) {
  footer {
    height: 790px; } }

@-webkit-keyframes fadein {
  0% {
    opacity: 0; }
  66% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadein {
  0% {
    opacity: 0; }
  66% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.DesktopApp .Nav {
  position: fixed;
  left: 0;
  top: 0;
  height: 75px;
  width: 100%;
  padding: 5px 0 0 0;
  background-color: white;
  z-index: 9999;
  border-bottom: 1px solid #e4e8ea; }
  .DesktopApp .Nav:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 5px;
    background: #5ad9e7;
    background: -webkit-gradient(linear, left top, right top, from(#5ad9e7), to(#01bbee));
    background: linear-gradient(to right, #5ad9e7 0%, #01bbee 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5ad9e7', endColorstr='#01bbee', GradientType=1); }
  .DesktopApp .Nav .logo-wrapper {
    display: inline-block;
    float: left;
    height: 70px;
    width: auto;
    padding: 5px 20px; }
    .DesktopApp .Nav .logo-wrapper .Logo {
      float: left;
      -webkit-transition: all ease-out 150ms;
      transition: all ease-out 150ms;
      cursor: pointer; }
      .DesktopApp .Nav .logo-wrapper .Logo:hover {
        opacity: 0.75;
        -webkit-transition: all ease-out 150ms;
        transition: all ease-out 150ms; }
  .DesktopApp .Nav ul {
    list-style-type: none;
    padding: 0;
    display: inline-block;
    width: auto; }
    .DesktopApp .Nav ul.menu {
      float: right;
      padding: 20px;
      border-left: 1px solid #e4e8ea;
      margin: 0; }
      .DesktopApp .Nav ul.menu li {
        float: left;
        display: inline-block;
        height: 30px; }
        .DesktopApp .Nav ul.menu li span {
          line-height: 20px;
          padding: 5px 10px;
          font-weight: 300 !important;
          display: inline-block;
          float: left;
          text-align: center; }
        .DesktopApp .Nav ul.menu li a {
          position: relative;
          line-height: 20px;
          padding: 5px 10px;
          color: #535252;
          font-size: 16px;
          font-weight: 300;
          display: inline-block;
          float: left;
          cursor: pointer;
          min-width: 100px;
          text-align: center;
          text-decoration: none; }
          .DesktopApp .Nav ul.menu li a:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: #e4e8ea;
            -webkit-transition: opacity ease-out 150ms, -webkit-transform ease-out 100ms;
            transition: opacity ease-out 150ms, -webkit-transform ease-out 100ms;
            transition: opacity ease-out 150ms, transform ease-out 100ms;
            transition: opacity ease-out 150ms, transform ease-out 100ms, -webkit-transform ease-out 100ms;
            opacity: 0;
            border-radius: 3px;
            -webkit-transform: scale(0.9);
                    transform: scale(0.9);
            z-index: -1; }
          .DesktopApp .Nav ul.menu li a:hover:before {
            opacity: 1;
            -webkit-transition: opacity ease-out 150ms, -webkit-transform ease-out 100ms;
            transition: opacity ease-out 150ms, -webkit-transform ease-out 100ms;
            transition: opacity ease-out 150ms, transform ease-out 100ms;
            transition: opacity ease-out 150ms, transform ease-out 100ms, -webkit-transform ease-out 100ms;
            -webkit-transform: scale(1);
                    transform: scale(1); }
          .DesktopApp .Nav ul.menu li a.border {
            border: 1px solid #e4e8ea;
            border-radius: 3px;
            height: 30px;
            margin-left: 5px;
            padding: 4px 10px;
            font-size: 15px; }
          .DesktopApp .Nav ul.menu li a.highlighted {
            color: white;
            font-size: 15px; }
            .DesktopApp .Nav ul.menu li a.highlighted:before {
              -webkit-transform: scale(1);
                      transform: scale(1);
              opacity: 1;
              background: #5ad9e7;
              background: -webkit-gradient(linear, left top, right top, from(#5ad9e7), to(#01bbee));
              background: linear-gradient(to right, #5ad9e7 0%, #01bbee 100%);
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5ad9e7', endColorstr='#01bbee', GradientType=1); }
            .DesktopApp .Nav ul.menu li a.highlighted:hover:before {
              opacity: 0.75; }
        .DesktopApp .Nav ul.menu li.current a {
          font-weight: bold; }
  .DesktopApp .Nav .hamburger {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    top: calc(50% - 8px);
    cursor: pointer;
    z-index: 1000; }
    .DesktopApp .Nav .hamburger:after {
      content: 'menu';
      position: absolute;
      color: #b2bcc1;
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      top: 0;
      right: 30px; }
    .DesktopApp .Nav .hamburger span {
      display: inline-block;
      position: relative;
      float: left;
      height: 2px;
      width: 100%;
      margin: 9px 0;
      background-color: #535252;
      transition: all ease-out 250ms;
      -moz-transition: all ease-out 250ms;
      -webkit-transition: all ease-out 250ms; }
      .DesktopApp .Nav .hamburger span:before, .DesktopApp .Nav .hamburger span:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        background-color: #535252;
        transition: all ease-out 250ms;
        -moz-transition: all ease-out 250ms;
        -webkit-transition: all ease-out 250ms;
        -webkit-transform-origin: center center;
                transform-origin: center center; }
      .DesktopApp .Nav .hamburger span:before {
        top: -6px; }
      .DesktopApp .Nav .hamburger span:after {
        bottom: -6px; }

@media (min-width: 769px) {
  .DesktopApp .hamburger {
    display: none; } }

@media (max-width: 991px) {
  .DesktopApp .Nav .logo-wrapper .hide {
    display: none; } }

@media (max-width: 768px) {
  .DesktopApp .Nav .hamburger {
    display: inline-block; }
  .DesktopApp .Nav ul {
    visibility: hidden;
    opacity: 0; }
    .DesktopApp .Nav ul.menu {
      height: auto;
      margin: 0;
      position: fixed;
      width: 200px;
      background-color: rgba(255, 255, 255, 0.95);
      right: 0;
      top: 5px;
      padding: 60px 20px 20px 20px;
      border-left: 1px solid #e4e8ea;
      border-bottom: 1px solid #e4e8ea;
      border-radius: 0 0 0 3px;
      box-shadow: 0 2px 8px rgba(80, 80, 80, 0.05); }
      .DesktopApp .Nav ul.menu li {
        width: 100%;
        margin: 10px 0;
        height: auto; }
        .DesktopApp .Nav ul.menu li span, .DesktopApp .Nav ul.menu li a {
          width: 100%;
          margin-left: 0 !important;
          margin-right: 0 !important; }
        .DesktopApp .Nav ul.menu li span {
          display: none; }
  .DesktopApp .Nav.menu-open .hamburger {
    z-index: 999; }
    .DesktopApp .Nav.menu-open .hamburger span {
      background-color: transparent;
      opacity: 0.75; }
      .DesktopApp .Nav.menu-open .hamburger span:before {
        -webkit-transform: rotate(45deg) translate(4px, 4px) !important;
                transform: rotate(45deg) translate(4px, 4px) !important; }
      .DesktopApp .Nav.menu-open .hamburger span:after {
        -webkit-transform: rotate(-45deg) translate(4.25px, -4.75px) !important;
                transform: rotate(-45deg) translate(4.25px, -4.75px) !important; }
    .DesktopApp .Nav.menu-open .hamburger:after {
      content: 'close'; }
    .DesktopApp .Nav.menu-open .hamburger:hover span {
      opacity: 1; }
  .DesktopApp .Nav.menu-open ul {
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity ease-out 250ms, visibility 250ms, -webkit-transform ease-out 250ms;
    transition: opacity ease-out 250ms, visibility 250ms, -webkit-transform ease-out 250ms;
    transition: opacity ease-out 250ms, transform ease-out 250ms, visibility 250ms;
    transition: opacity ease-out 250ms, transform ease-out 250ms, visibility 250ms, -webkit-transform ease-out 250ms;
    -webkit-transform: scale(1);
            transform: scale(1); } }

.DesktopApp section article {
  position: relative;
  display: inline-block;
  float: left;
  width: 100%; }
  .DesktopApp section article h2 {
    position: relative;
    display: inline-block;
    float: left;
    text-align: center;
    width: 100%;
    line-height: 40px;
    font-size: 36px;
    font-weight: 300;
    color: #535252;
    margin: 30px 0;
    padding: 0 20px; }

.DesktopApp section aside + article {
  width: calc(100% - 355px); }

@media (max-width: 480px) {
  .DesktopApp article h2 {
    line-height: 30px;
    font-size: 28px; } }

.Container {
  margin-left: auto;
  margin-right: auto;
  clear: both;
  max-width: 100%;
  position: relative; }

@media (max-width: 767px) {
  .DesktopApp .Container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px; } }

@media (min-width: 768px) {
  .DesktopApp .Container {
    width: 750px; } }

@media (min-width: 992px) {
  .DesktopApp .Container {
    width: 970px; } }

@media (min-width: 1200px) {
  .DesktopApp .Container {
    width: 1170px; } }

.MobileApp .Page {
  display: inline-block;
  float: left;
  width: 100%; }
  .MobileApp .Page.moment-show, .MobileApp .Page.moment-edit, .MobileApp .Page.moment-create, .MobileApp .Page.image-edit, .MobileApp .Page.image-select, .MobileApp .Page.maps-show, .MobileApp .Page.login, .MobileApp .Page.register {
    width: 100%;
    float: left;
    display: inline-block;
    background-color: white;
    min-height: calc(100vh - 40px); }
  .MobileApp .Page.image-select {
    padding-top: 8px; }
  .MobileApp .Page.moment-edit .ImageSelectHeader, .MobileApp .Page.moment-create .ImageSelectHeader {
    margin-bottom: -90px; }
  .MobileApp .Page.image-edit .MapsEdit {
    margin-bottom: -45px; }
  .MobileApp .Page.moment-edit .Wrapper.image-wrapper, .MobileApp .Page.moment-create .Wrapper.image-wrapper {
    margin-bottom: 10px; }
  .MobileApp .Page.moment-edit .Container, .MobileApp .Page.moment-create .Container {
    margin-bottom: 100px; }
  .MobileApp .Page.moment-create {
    padding-top: 15px; }

.DesktopApp .Page {
  display: inline-block;
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 380px);
  padding: 0 0 30px 0;
  -webkit-animation: FadeIn;
          animation: FadeIn;
  -webkit-animation-duration: 250ms;
          animation-duration: 250ms;
  -webkit-animation-direction: forward;
          animation-direction: forward; }
  .DesktopApp .Page .linkfooter {
    position: absolute;
    height: 40px;
    padding: 10px 0;
    line-height: 20px;
    text-align: center;
    left: 0;
    bottom: 0;
    width: 100%; }
    .DesktopApp .Page .linkfooter a {
      line-height: 20px;
      font-size: 12px;
      color: #777e80; }
      .DesktopApp .Page .linkfooter a:hover {
        text-decoration: underline; }
  .DesktopApp .Page.home {
    display: inline-block;
    width: 100%;
    float: left;
    background-color: white;
    text-align: center; }
  .DesktopApp .Page.index-moments, .DesktopApp .Page.my-trips {
    display: inline-block;
    width: 100%;
    float: left;
    text-align: center;
    overflow: hidden; }
  .DesktopApp .Page.my-trips {
    padding: 90px 20px 20px 20px; }
  .DesktopApp .Page.photoalbum-explanation {
    padding: 20px; }
  .DesktopApp .Page.index-moments {
    padding: 30px 20px 20px 20px; }
  .DesktopApp .Page.photoalbum-preview, .DesktopApp .Page.trip-create, .DesktopApp .Page.trip-edit, .DesktopApp .Page.moment-create, .DesktopApp .Page.moment-edit, .DesktopApp .Page.image-select, .DesktopApp .Page.image-edit, .DesktopApp .Page.error-page {
    display: inline-block;
    text-align: center;
    padding-top: 90px; }
  .DesktopApp .Page.login, .DesktopApp .Page.register {
    padding: 90px 0 0 0; }
  .DesktopApp .Page.my-account, .DesktopApp .Page.recover-password, .DesktopApp .Page.reset-password {
    padding: 30px 0; }
  .DesktopApp .Page.my-account, .DesktopApp .Page.recover-password, .DesktopApp .Page.reset-password, .DesktopApp .Page.login, .DesktopApp .Page.register {
    display: inline-block;
    width: 100%;
    float: left;
    text-align: center;
    position: relative; }
    .DesktopApp .Page.my-account:before, .DesktopApp .Page.recover-password:before, .DesktopApp .Page.reset-password:before, .DesktopApp .Page.login:before, .DesktopApp .Page.register:before {
      content: '';
      position: absolute;
      background-color: #f4f6f7;
      border-bottom: 1px solid #e4e8ea;
      height: 180px;
      width: 100%;
      left: 0;
      top: 0; }
  .DesktopApp .Page.trip-show {
    padding: 80px 20px 0 20px; }
  .DesktopApp .Page.my-trips, .DesktopApp .Page.trip-show, .DesktopApp .Page.photoalbum-explanation {
    text-align: center; }
    .DesktopApp .Page.my-trips h2, .DesktopApp .Page.trip-show h2, .DesktopApp .Page.photoalbum-explanation h2 {
      margin-bottom: 30px; }
  .DesktopApp .Page.moment-show {
    padding: 80px 20px 20px 20px;
    width: 70%; }
  .DesktopApp .Page.trip-edit .Container, .DesktopApp .Page.moment-create .Container {
    margin-bottom: 105px; }

@media (max-width: 640px) {
  .DesktopApp .Page.index-moments, .DesktopApp .Page.my-trips, .DesktopApp .Page.moment-show, .DesktopApp .Page.trip-show {
    padding-left: 0;
    padding-right: 0; }
  .DesktopApp .Page.moment-show, .DesktopApp .Page.trip-show {
    padding-top: 0; }
  .DesktopApp .Page.my-account:before {
    height: 120px; } }

@media (max-width: 991px) {
  .DesktopApp .Page.moment-show {
    width: 100%; } }

.PageTitle {
  display: inline-block;
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 30px; }
  .PageTitle h2 {
    margin: 0 !important; }
  .PageTitle .Button {
    position: absolute;
    left: 0;
    top: 0; }

.DesktopApp section {
  position: relative;
  display: inline-block;
  float: left;
  width: 100%; }
  .DesktopApp section h2 {
    position: relative;
    display: inline-block;
    float: left;
    width: 100%;
    line-height: 40px;
    font-size: 36px;
    font-weight: 300;
    text-align: center;
    color: #535252;
    margin: 0 0 30px 0; }
  .DesktopApp section p {
    text-align: inherit; }

